.inner {
  padding-top: calc(var(--mantine-spacing-lg) * 4);
  padding-bottom: calc(var(--mantine-spacing-lg) * 4);

  background-color: light-dark(var(--brand-color-dark), var(--brand-color-dark));

}

.subText {
  max-width: rem(610px);

}

.title {
  font-size: rem(56px);
  line-height: rem(64px);
  font-weight: 500;
}