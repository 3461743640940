.footer {
  /* margin-top: rem(120px); */
  padding-top: calc(var(--mantine-spacing-md) * 3);
  /* padding-bottom: calc(var(--mantine-spacing-xl) * 2); */
  background-color: light-dark(var(--brand-color-dark), var(--brand-color-dark));
  /* border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5)); */

}

.link {
  display: block;
  color: light-dark(var(--text-sec-dark), var(--text-sec-dark));
  font-size: var(--mantine-font-size-sm);
  padding-top: rem(3px);
  /* padding-bottom: rem(3px); */

  &:hover {
    text-decoration: underline;
  }
}

.title {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  /* margin-bottom: calc(var(--mantine-spacing-xs) / 2); */
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.afterFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-sm);
  /* padding-bottom: var(--mantine-spacing-xl); */
  border-top: rem(1px) solid light-dark(var(--text-sec-dark), var(--text-sec-dark));


}