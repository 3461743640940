.headerSticky {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 10; /* Adjust as needed */
}

.header {
  /* height: rem(60px); */
  background-color: light-dark(var(--brand-color-dark), var(--brand-color-dark));

  /* padding-left: calc(var(--mantine-spacing-lg) * 4);
  padding-right: calc(var(--mantine-spacing-lg) * 4); */
}