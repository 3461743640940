.HeroSection_inner__MsFN_ {
  padding-top: calc(var(--mantine-spacing-lg) * 4);
  padding-bottom: calc(var(--mantine-spacing-lg) * 4);

  background-color: var(--brand-color-dark);

}

  [data-mantine-color-scheme='dark'] .HeroSection_inner__MsFN_ {

  background-color: var(--brand-color-dark);

}

.HeroSection_subText__eQB9T {
  max-width: calc(38.125rem * var(--mantine-scale));

}

.HeroSection_title__im1kR {
  font-size: calc(3.5rem * var(--mantine-scale));
  line-height: calc(4rem * var(--mantine-scale));
  font-weight: 500;
}
.Footer_footer__0xAYS {
  /* margin-top: rem(120px); */
  padding-top: calc(var(--mantine-spacing-md) * 3);
  /* padding-bottom: calc(var(--mantine-spacing-xl) * 2); */
  background-color: var(--brand-color-dark);
  /* border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5)); */

}
  [data-mantine-color-scheme='dark'] .Footer_footer__0xAYS {
  background-color: var(--brand-color-dark);

}

.Footer_link__xV_5D {
  display: block;
  color: var(--text-sec-dark);
}

[data-mantine-color-scheme='dark'] .Footer_link__xV_5D {
  color: var(--text-sec-dark);

}

.Footer_link__xV_5D {
  font-size: var(--mantine-font-size-sm);
  padding-top: calc(0.1875rem * var(--mantine-scale));

}

/* padding-bottom: rem(3px); */

.Footer_link__xV_5D:hover {
    text-decoration: underline;
  }

.Footer_title__9_AUT {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  /* margin-bottom: calc(var(--mantine-spacing-xs) / 2); */
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .Footer_title__9_AUT {
  color: var(--mantine-color-white);

}

.Footer_afterFooter__QCOpQ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-sm);
  /* padding-bottom: var(--mantine-spacing-xl); */
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--text-sec-dark);


}

[data-mantine-color-scheme='dark'] .Footer_afterFooter__QCOpQ {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--text-sec-dark);

}
.Header_headerSticky__mbGtP { /* For Safari */
  position: sticky;
  top: 0;
  z-index: 10; /* Adjust as needed */
}

.Header_header__BOUXi {
  /* height: rem(60px); */
  background-color: var(--brand-color-dark);

  /* padding-left: calc(var(--mantine-spacing-lg) * 4);
  padding-right: calc(var(--mantine-spacing-lg) * 4); */
}

[data-mantine-color-scheme='dark'] .Header_header__BOUXi {
  background-color: var(--brand-color-dark)
}
